@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");
@import url(https://fonts.googleapis.com/css?family=Press+Start+2P);

/* Global Styles */
body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #161616;
  color: #fafafa;
  text-align: center;
  font-family: "VT323", monospace;
  background-image: url('/public/images/scurry.webp');
  background-size: cover; /* Cover the entire page */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat;
}

/* App layout */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-header {
  /* background-color: #161616; */
  margin-top: 40px;
  font-size: 85px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
}

/* Input styles */
.input-container, .user-input {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  /* background-color: #161616; */
}

input[type="text"] {
  padding: 10px;
  font-size: 22px;
  width: 230px;
  border-radius: 10px;
  font-family: "VT323", monospace;
}

button {
  padding: 10px 20px;
  background-color: #4c4c4c;
  color: #fafafa;
  border: whitesmoke;
  border-radius: 10px;
  font-size: 26px;
  cursor: pointer;
  font-family: "VT323", monospace;
}

.toast {
  font-family: "VT323", monospace;
}

/* Ticker styles */
.pixl-ticker {
  overflow: hidden;
  white-space: nowrap;
  /* background-color: #161616; */
  color: #fafafa;
  font-family: "VT323", monospace;
  font-size: 32px;
  margin-top: 10px;
  user-select: none;
}

.ticker-wrap {
  width: 100%;
  overflow: hidden;
}

@keyframes move {
  0% {
    transform: translate3d(75vw, 0, 0);
  }
  100% {
    transform: translate3d(-75vw, 0, 0);
  }
}

.ticker-move {
  display: inline-block;
  animation: move 20s linear infinite;
}

.ticker-item {
  display: inline-block;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ticker-img {
  animation: rotate 5s linear infinite;
}

.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Pixl Text Style */
.pixl-text {
  /* background-color: #161616; */
  color: #fafafa;
  font-family: "VT323", monospace;
  font-size: 32px;
  margin-top: 40px;
}

/* Pet and Metadata */
.outermost-pet-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
  margin-top: 10px;
  padding-bottom: 10px;
}

.card-holder {
  border-radius: 15px;
  background-color: rgb(34, 33, 33);
  margin: 20px;
  margin-top: 30px;
  margin-bottom: 25px;
  max-width: 500px;
  max-height: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  animation: swing 3.5s infinite alternate ease-in-out;
}

.metadata-table {
  border-collapse: collapse;
  box-shadow: 0 0 5px 5px #525252;
  max-width: 600px;
  border-radius: 20px;
  background-color: rgb(34, 33, 33);
}

.metadata-trait-header th {
  text-align: center;
  align-items: center;
  justify-self: center;
}


.metadata-table:hover {
  animation-play-state: paused;
}


.metadata-table th {
  padding: 2px;
  padding-left: 30px;
  padding-bottom: 15px;
  text-align: center;
  color:#e4e4e4;
  font-size: 30px;
  font-weight: bold;
}
.metadata-table td {
  padding: 3px;
  padding-left: 30px;
  text-align: center;
  color: #d5d4d4;
  /* background-color: #252424; */
  font-size: 26px;
}

.metadata-api-down {
  font-size: 22px;
  font-weight: normal;
}

@keyframes swing {
  0% {
    transform: perspective(1000px) rotateY(-10deg);
  }
  100% {
    transform: perspective(1000px) rotateY(10deg);
  }
}

/* .card-image {
  width: 100%;
  display: block;
} */

/* OpenSea Message Below Card */
.os-pet-api {
  font-size: 20px;
  color: #898888;
  margin-bottom: 40px;
}

.confirmation-message {
  font-size: 20px;
  color: #898888;
  margin-bottom: 40px;
}

.mismatch-message {
  font-size: 20px;
  color: #898888;
  margin-bottom: 40px;
}

/* Signature style */
.signature-text {
  /* background-color: #161616; */
  font-family: "VT323", monospace;
  font-size: 22px;
  margin-top: 0px;
}

.sealuminati {
  color: #fafafa;
  text-decoration: underline;
}

.ethereum-tip {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
}

.tip-text {
  font-size: 20px;
  color: #898888;
}

/* Footer styles */
.footer {
  width: 100%;
  /* background-color: #161616; */
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "VT323", monospace;
  font-size: 25px;
  margin-top: 5px;
}

.footer a {
  color: #fafafa;
  text-decoration: none;
  margin: 0 10px;
  display: flex;
  align-items: center;
}

.footer img {
  margin-right: 5px;
}


/* Media Queries */
@media screen and (max-width: 600px) {
  .card-holder {
    max-width: 250px;
    margin: 10px auto;
  }

  .metadata-table {
    border-collapse: collapse;
    border: 2px solid #ffffff;
    max-width: 300px;
  }

  /* Footer styles */
  .footer {
    display: none;
  }

  .App-header {
    font-size: 50px;
    margin-top: 10px;
    margin-bottom: -10px;
  }

  @keyframes move {
    0% {
      transform: translate3d(100vw, 0, 0);
    }
    100% {
      transform: translate3d(-200vw, 0, 0);
    }
  }

  input[type="text"] {
    width: 220px;
  }

  .signature-text {
    margin-bottom: 20px;
  }

  .tip-text {
    font-size: 18px;
  }

  .ethereum-tip {
    margin-bottom: 10px;
  }

  .eth-image {
    margin-left: 20px;
    margin-right: -10px;
  }

  .pixl-text {
    margin-top: 10px;
    font-size: 20px;
  }

  .card-holder {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .pixl-ticker, .os-pet-api, .confirmation-message, .mismatch-message {
    font-size: 20px;
  }

  .ticker-wrap, .ticker-move, .ticker-item, .ticker-img {
    font-size: 20px;
  }
}
